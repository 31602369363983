import React, { useCallback, useEffect, useState } from "react";
import RegisterContent from "../../components/RegisterContent";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { navigate, Script } from "gatsby";
import Layout from "../../components/layout/Layout";
import { getQueryString } from "../../utils/helper";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const Register = () => {
	const { isLoggedIn, userId, email } = useJWTChecker();
	// const userDetails = JWTChecker();
	const [userStatus, setUserStatus] = useState(true); // {*** FALSE -> Register ** TRUE -> Login ***}
	const [showMyAccount, setShowMyAccount] = useState(false);

	useEffect(() => {
		// console.log("jwtChecker userDetails", isLoggedIn, userId, email);
		if (isLoggedIn && email) {
			setShowMyAccount(true);
		} else {
			setShowMyAccount(false);
		}
	}, [isLoggedIn, email]);

	const setAuthType = useCallback(() => {
		const redirectUrl = getQueryString("r");

		let url = "/login";

		if (redirectUrl) {
			url = `${url}?r=${redirectUrl}`;
		}

		navigate(url+location.search);
	}, []);

	return (
		<Layout>
			<RegisterContent goToLogin={setAuthType} />
		</Layout>
	);
};

export const Head = (props: any) => {
	return (
		<>
			<title>Register</title>
			
		</>
	);
};

export default Register;
